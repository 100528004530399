import React from 'react'
import Layout from '../components/layout'

const Kontakt = () => (
  <Layout>
    <h1>Kontakt</h1>
    <p>TBD.</p>
  </Layout>
)

export default Kontakt
